body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    height: 100%;
    width: 100%;
}

#app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    background: #fafafa;
    height: 100%;
}

#app:-webkit-full-screen {
	width: 100%;
	height: 100%;
}

.flex-item {
    width: 100%;
    align-self: center;
    display: flex;
    flex-flow: column;
    max-height: 100%;
    min-height: 100vh;
    height: 100%;
}

.content {
    flex: 1 1;
    overflow-x: hidden;
}

canvas[resize] {
    height: 100%;
    width: 100%;
    display: inline-block;
    position: relative;
}

a:link    {
    text-decoration:  none;
    font-weight:      bold;
    color:            #FFC107;
}
a:visited {
    text-decoration:  none;
    font-weight:      bold;
    color:            #FFC107;
}
a:hover   {
    text-decoration:  none;
    font-weight:      bold;
    color:            #FFD54F;
}
a:active  {
    text-decoration:  none;
    font-weight:      bold;
    color:            #FF6F00;
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}

.starContainer {
    flex: 1 1;
    justify-content: center;
}

.starActive {
    color: #FFB300;
}

.cardContainer {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 50px);
}

.fullWidth {
    width: 100%;
}


.editor-small > .ql-container > .ql-editor {
    min-height: 300px;
    max-height: 300px;
}


.editor-medium > .ql-container > .ql-editor {
    min-height: 500px;
    max-height: 500px;
}


.editor-large > .ql-container > .ql-editor {
    min-height: 800px;
    max-height: 800px;
}

#printable {
	display: none;
	visibility: hidden;
}


@media print {
    #app * {
        visibility: hidden;
    }
    #printable, #printable * {
        visibility: visible;
    }
    #printable {
	    display: inline;
	    display: initial;
        position: absolute;
        left: 0;
        top: 0;
	    page-break-before: always;
        width: 100%;
    }
    #printable img {
	    max-width: 100%;
	    max-height: 100%;
    }
    #printable.ql-editor {
	    height: auto;
	    font-size: 12px;
    }
    @page {
        margin: 1.27cm;
    }
    .report-wrapper {
        width: 100%;
        height: 100%;
    }
    .report-letterhead {
        min-height: 100px;
        max-height: 200px;
        width: 100%;
        overflow: hidden;
        margin-bottom: 32px;
        display: flex;
    }
    .report-letterhead > .report-top-3 {
        float: left;
        width: calc(100% / 3);
        min-height: 100px;
        max-height: 200px;
        overflow: hidden;
    }

    .report-letterhead > .report-top-3  img {
        max-height: 170px !important;
        max-width: 100%;
    }
    .report-letterhead > .report-body {
        width: 100%;
    }
}


.test {
    background: blue;
}
